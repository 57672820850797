import { actions } from "./actions";
import { initialState } from "./index";

function storeCurrentBid(bid) {
  localStorage.setItem("currentBid", bid ?? null);
}

function getCurrentBid() {
  const bid = localStorage.getItem("currentBid");
  return bid === "null" ? null : bid;
}

function clearCurrentBid() {
  localStorage.removeItem("currentBid");
}

const Reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_USER_DATA:
      let currentBid = state.currentBid;
      let businessSettings = state.businessSettings;
      const businessIds = Object.keys(action.user?.businesses || {});
      // Pull out the requested bid if its set
      const storedBid = getCurrentBid();
      if (storedBid && !currentBid) {
        currentBid = storedBid;
        businessSettings = {};
      }
      // Set the current bid if it's not set or we don't have access
      if (!currentBid || (!businessIds.includes(currentBid) && !state.token?.claims.admin)) {
        const businesses = Object.keys(action.user?.businesses || {});
        if (businesses.length === 1) {
          currentBid = businesses[0];
        } else {
          currentBid = action.user?.preferredBusiness;
        }
        storeCurrentBid(currentBid);
        businessSettings = {};
      }
      // Remove any business data we shouldn't have access to
      for (const bid in Object.keys(state.businesses || {})) {
        if (!businessIds.includes(bid)) {
          delete state.businesses[bid];
        }
      }
      return {
        ...state,
        uid: action.uid,
        user: action.user,
        currentBid,
        showBusinessPicker: !currentBid,
        businessIds,
        businessSettings,
        isReportingOnly: currentBid ? currentBid.includes(",") : false,
      };
    case actions.SET_USER_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case actions.SET_BUSINESS_DATA:
      if (!state.businessIds.includes(action.bid)) {
        if (state.token?.claims?.adminAccess?.b === action.bid && state.currentBid === action.bid) {
          return {
            ...state,
            business: action.business,
          };
        }
        return state;
      }
      if (state.currentBid === action.bid) {
        return {
          ...state,
          business: action.business,
          businesses: {
            ...state.businesses,
            [action.bid]: action.business,
          },
        };
      }
      return {
        ...state,
        businesses: {
          ...state.businesses,
          [action.bid]: action.business,
        },
      };
    case actions.SET_BUSINESS_SETTINGS:
      if (state.currentBid !== action.bid) {
        return state;
      }
      return {
        ...state,
        businessSettings: action.businessSettings,
      };
    case actions.SET_REQUESTED_BUSINESS:
      const bIds = Object.keys(state?.user?.businesses || []);
      if (bIds.includes(action.bid)) {
        storeCurrentBid(action.bid);
      }
      if (state.token?.claims.adminAccess?.b === action.bid || bIds.includes(action.bid) || action.isReportingOnly) {
        return {
          ...state,
          currentBid: action.bid,
          showBusinessPicker: false,
          isReportingOnly: action.isReportingOnly,
          business: state.businesses?.[action.bid] || {},
        };
      } else {
        return state;
      }
    case actions.LOGOUT:
      clearCurrentBid();
      return {
        ...initialState,
      };
    case actions.SET_DATE_RANGE:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case actions.SHOW_BUSINESS_PICKER:
      return {
        ...state,
        showBusinessPicker: true,
      };
    case actions.HIDE_BUSINESS_PICKER:
      return {
        ...state,
        showBusinessPicker: false,
      };
    case actions.SET_VISITED_BUSINESS_DATA:
      return {
        ...state,
        visitedBusiness: action.visitedBusiness,
      };
    default:
      return state;
  }
};

export default Reducer;
