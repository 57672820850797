import React, { useContext } from "react";
import PropTypes from "prop-types";
import { NavLink, useHistory } from "react-router-dom";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import layoutHelpers from "./helpers";
import { navbarBg } from "./Config";
import { actions, Context } from "../../store";
import { getPath } from "../../routes";
import { StringsContext } from "../../strings";
import yn from "yn";
import Countdown from "react-countdown";
import "./LayoutNavbar.scss";
import { activeAdminCheck, adminCheck } from "../Utils";
import toast from "react-hot-toast";

function LayoutNavbar(props) {
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const { tl } = useContext(StringsContext);

  const toggleSidenav = function (e) {
    e.preventDefault();
    layoutHelpers.toggleCollapsed();
  };

  const showBusinessSwitcher = e => {
    dispatch({
      type: actions.SHOW_BUSINESS_PICKER,
    });
  };

  const handleCountdownComplete = () => {
    toast.error("Business access expired.");
    setTimeout(() => {
      if (state.businessIds.length === 1) {
        history.push(`/b/${state.businessIds[0]}`);
      } else {
        let lastAccessedBid = null;
        if (window.lastAccessedBid) {
          lastAccessedBid = window.lastAccessedBid;
        } else {
          lastAccessedBid = state.businessIds[0];
        }
        toast.success(`Redirecting you to your business.`);
        history.push(`/b/${lastAccessedBid}`);
      }
    }, 1000);
  };

  const logout = async e => {
    await props.logout(e);
    history.push(getPath("home"));
  };

  const b = state.token?.claims?.adminAccess?.b;
  const e = state.token?.claims?.adminAccess?.e;

  return (
    <Navbar bg={navbarBg} expand="lg" className="layout-navbar align-items-lg-center container-p-x">
      {/* Brand */}
      <Navbar.Brand as={NavLink} to="/">
        <img
          src={`/assets/${process.env.REACT_APP_FLAVOUR}/color-logo.png`}
          alt={!yn(process.env.REACT_APP_HIDE_NAVBAR_TITLE) ? "" : tl("Airpay POS")}
        />
        {!yn(process.env.REACT_APP_HIDE_NAVBAR_TITLE) ? tl("Airpay POS") : ""}
      </Navbar.Brand>

      {/* Sidenav toggle */}
      {props.sidenavToggle && (
        <button
          className="sidenav burger-icon mr-auto"
          onClick={toggleSidenav}
          aria-label="Toggle sidebar navigation menu"
        >
          <div className="bar top-bar"></div>
          <div className="bar middle-bar"></div>
          <div className="bar bottom-bar"></div>
        </button>
      )}

      {activeAdminCheck(state) && (
        <Nav className="align-items-lg-center mr-auto ml-2">
          <Nav.Item className="nav-item badge bg-dark text-white" aria-label="Admin">
            <i className="fas fa-lock-open fa-fw mr-1"></i>
            <Countdown date={e * 1000} daysInHours={true} onComplete={handleCountdownComplete}>
              <>Access Expired</>
            </Countdown>
          </Nav.Item>
        </Nav>
      )}

      {/* Navbar toggle */}
      <Navbar.Toggle className="dropdown burger-icon" aria-label="Toggle navbar dropdown menu">
        <div className="bar top-bar"></div>
        <div className="bar middle-bar"></div>
        <div className="bar bottom-bar"></div>
      </Navbar.Toggle>

      <Navbar.Collapse>
        <Nav className="align-items-lg-center mr-auto"></Nav>
        <Nav>
          <Dropdown as={Nav.Item} alignRight={true}>
            <Dropdown.Toggle as={Nav.Link} className="d-flex align-items-center">
              {adminCheck(state) ? (
                <i className={`fas fa-fw fa-user-headset mr-2`}></i>
              ) : (
                <i className={`fas fa-fw fa-user mr-2`}></i>
              )}
              <span className="d-inline-block text-truncate" style={{ maxWidth: "250px" }}>
                {state.user?.name || state.user?.email || "User"}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {state.businessIds.length > 1 && (
                <>
                  <Dropdown.Item onClick={showBusinessSwitcher}>
                    <i className="fas fa-fw fa-store-alt mr-2 text-primary"></i> Switch business
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </>
              )}
              <Dropdown.Item as={NavLink} to={getPath("changePassword")} activeClassName="">
                <i className="fas fa-fw fa-lock mr-2 text-info"></i> Change password
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={logout}>
                <i className="fas fa-fw fa-sign-out-alt mr-2 text-danger"></i> Sign out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

LayoutNavbar.propTypes = {
  sidenavToggle: PropTypes.bool,
};

LayoutNavbar.defaultProps = {
  sidenavToggle: true,
};

export default LayoutNavbar;
