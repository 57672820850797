export const actions = {
  SET_USER_DATA: "SET_USER_DATA",
  SET_BUSINESS_DATA: "SET_BUSINESS_DATA",
  SET_VISITED_BUSINESS_DATA: "SET_VISITED_BUSINESS_DATA",
  SET_BUSINESS_SETTINGS: "SET_BUSINESS_SETTINGS",
  SET_REQUESTED_BUSINESS: "SET_REQUESTED_BUSINESS",
  SET_USER_TOKEN: "SET_USER_TOKEN",
  LOGOUT: "LOGOUT",
  SET_DATE_RANGE: "SET_DATE_RANGE",
  SHOW_BUSINESS_PICKER: "SHOW_BUSINESS_PICKER",
  HIDE_BUSINESS_PICKER: "HIDE_BUSINESS_PICKER",
};
