import React, { useContext } from "react";
import { StringsContext } from "../../strings";
import { Card } from "react-bootstrap";
import styles from "./403.module.scss";
import { Context } from "../../store";
import { useHistory } from "react-router-dom";

const Error403 = () => {
  const history = useHistory();
  const [state] = useContext(Context);
  const { tl } = useContext(StringsContext);

  let lastAccessedBid = (window as any).lastAccessedBid;
  if (lastAccessedBid !== state.currentBid) {
    history.push("/");
  }

  return (
    <>
      <Card className={styles.card}>
        <Card.Body className={styles.body}>
          <div className={styles.container}>
            <h1 className={styles.status}>{tl("403")}</h1>
            <p className={styles.message}>{tl("You do not have permission to access this page.")}</p>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Error403;
