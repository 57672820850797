import React from "react";
import loadable, { LoadableComponent } from "@loadable/component";
import pMinDelay from "p-min-delay";
import Loader from "./shared/Loader";

// Layouts
import Layout2 from "./shared/layouts/Layout2";
import LayoutBlank from "./shared/layouts/LayoutBlank";
import { generatePath } from "react-router-dom";
import yn from "yn";
import { MainState } from "./store";
import { Permissions } from "./types";
import { activeAdminCheck, adminCheck } from "./shared/Utils";

// Lazy load component
const lazy = (cb: any) => loadable<any>(() => pMinDelay(cb(), 200), { fallback: <Loader /> });

// ---
// Default application layout

export const DefaultLayout = Layout2;

// ---
// Document title template

export const titleTemplate = `%s - ${process.env.REACT_APP_NAME}`;

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

type Route = {
  title: string;
  path: string;
  component: LoadableComponent<any>;
  layout?: React.ComponentType<any>;
  private?: boolean;
  scrollToTop?: boolean;
  exact?: boolean;
  reportingOnlyAccess?: boolean;
  authorizedCallback?: (state: MainState, role: string, permissions: Permissions) => boolean;
};

const showInvoices = (state: MainState) =>
  ["invoicing", "hybrid", "hospitality"].includes(state.business.businessMode!) || state.isReportingOnly;
const hasPermission = (
  state: MainState,
  permissions: Permissions,
  permission: keyof Permissions,
  requireSuperAdmin: boolean = false
) => !!(activeAdminCheck(state, requireSuperAdmin) || permissions[permission]);

export const defaultRoute = "/";
const namedRoutes: {
  [routeName: string]: Route;
} = {
  // Admin
  businessSearch: {
    title: "Business Search",
    path: "/admin/business/search",
    exact: false,
    authorizedCallback: state => adminCheck(state),
    component: lazy(() => import("./components/admin/AdminBusinessSearch")),
  },
  // Global
  home: {
    title: "Home",
    path: "/",
    component: lazy(() => import("./components/Home")),
  },
  login: {
    title: "Login",
    path: "/login",
    layout: LayoutBlank,
    private: false,
    component: lazy(() => {
      return yn(process.env.REACT_APP_LOGINV2) ? import("./components/Loginv2") : import("./components/Login");
    }),
  },
  supportLogin: {
    title: "Support Login",
    path: "/support-login",
    layout: LayoutBlank,
    private: false,
    component: lazy(() => import("./components/SupportLogin")),
  },
  // User
  changePassword: {
    title: "Change Password",
    path: "/change-password",
    component: lazy(() => import("./components/user/ChangePassword")),
  },
  // Contacts
  contacts: {
    title: "Customers",
    path: "/customers/:contactId?",
    scrollToTop: false,
    exact: false,
    authorizedCallback: (state, role, permissions) => hasPermission(state, permissions, "manageCustomers"),
    component: lazy(() => import("./components/contacts/Contacts")),
  },
  // Products
  products: {
    title: "Items",
    path: "/items/:productId?",
    scrollToTop: false,
    exact: false,
    authorizedCallback: (state, role, permissions) => hasPermission(state, permissions, "manageItems"),
    component: lazy(() => import("./components/products/Products")),
  },
  // Categories
  categories: {
    title: "Categories",
    path: "/categories/:categoryId?",
    scrollToTop: false,
    exact: false,
    authorizedCallback: (state, role, permissions) => hasPermission(state, permissions, "manageCategories"),
    component: lazy(() => import("./components/categories/Categories")),
  },
  // Discounts
  discounts: {
    title: "Discounts",
    path: "/discounts/:discountId?",
    scrollToTop: false,
    exact: false,
    authorizedCallback: (state, role, permissions) => hasPermission(state, permissions, "manageDiscounts"),
    component: lazy(() => import("./components/discounts/Discounts")),
  },
  // Surcharges
  surcharges: {
    title: "Surcharges",
    path: "/surcharges/:surchargeId?",
    scrollToTop: false,
    exact: false,
    authorizedCallback: (state, role, permissions) => hasPermission(state, permissions, "manageSurcharges"),
    component: lazy(() => import("./components/surcharges/Surcharges")),
  },
  // Modifier Sets
  modifiers: {
    title: "Modifier Sets",
    path: "/modifiers/:modifierSetId?",
    scrollToTop: false,
    exact: false,
    authorizedCallback: (state, role, permissions) => hasPermission(state, permissions, "manageModifiers"),
    component: lazy(() => import("./components/modifiers/ModifierSets")),
  },
  // Data Management
  data: {
    title: "Data Management",
    path: "/data",
    exact: false,
    authorizedCallback: (state, role) => role === "owner" || activeAdminCheck(state),
    component: lazy(() => import("./components/data/DataManagement")),
  },
  // Account Security
  security: {
    title: "Account Security",
    path: "/security",
    exact: false,
    authorizedCallback: state => !activeAdminCheck(state),
    component: lazy(() => import("./components/user/Security")),
  },
  invoices: {
    title: "Invoices",
    path: "/invoices/:businessId?/:invoiceId?",
    scrollToTop: false,
    exact: false,
    reportingOnlyAccess: true,
    authorizedCallback: state => showInvoices(state),
    component: lazy(() => import("./components/invoices/Invoices")),
  },
  paid: {
    title: "Paid",
    path: "/paid/:businessId?/:invoiceId?",
    scrollToTop: false,
    exact: false,
    reportingOnlyAccess: true,
    component: lazy(() => import("./components/invoices/Invoices")),
  },
  reportSummary: {
    title: "Report Summary",
    path: "/summary",
    exact: false,
    reportingOnlyAccess: true,
    component: lazy(() => import("./components/reports/Summary")),
  },
  reportItemSales: {
    title: "Report Item Sales",
    path: "/item-sales",
    exact: false,
    component: lazy(() => import("./components/reports/ItemSales")),
  },
  payments: {
    title: "Payments",
    path: "/payments/:businessId?/:invoiceId?",
    exact: false,
    reportingOnlyAccess: true,
    component: lazy(() => import("./components/invoices/Payments")),
  },
  businessMode: {
    title: "Business Mode",
    path: "/business/mode",
    exact: false,
    authorizedCallback: (state, role, permissions) => hasPermission(state, permissions, "manageBusinessMode"),
    component: lazy(() => import("./components/business/BusinessMode")),
  },
  businessDetails: {
    title: "Business Details",
    path: "/business/details",
    exact: false,
    authorizedCallback: (state, role, permissions) => hasPermission(state, permissions, "manageBusiness", true),
    component: lazy(() => import("./components/business/BusinessDetails")),
  },
  businessUsers: {
    title: "Additional Users",
    path: "/business/users/:userId?",
    scrollToTop: false,
    exact: false,
    authorizedCallback: (state, role, permissions) => hasPermission(state, permissions, "manageUsers"),
    component: lazy(() => import("./components/business/Users")),
  },
  cardPayments: {
    title: "Card Payments",
    path: "/card-payments",
    exact: false,
    authorizedCallback: (state, role, permissions) => hasPermission(state, permissions, "manageBusiness"),
    component: lazy(() => import("./components/card-payments/CardPayments")),
  },
  permissions: {
    title: "Roles & Features",
    path: "/business/features/:role?",
    scrollToTop: false,
    exact: false,
    authorizedCallback: (state, role, permissions) =>
      (permissions?.managePermissions && role === "owner") || activeAdminCheck(state),
    component: lazy(() => import("./components/business/Permissions")),
  },
  about: {
    title: "About",
    path: "/about",
    exact: false,
    reportingOnlyAccess: true,
    component: lazy(() => import("./components/About")),
  },
};

export const routes = Object.values(namedRoutes);

interface StringMap {
  [key: string]: string;
}
export const getPath = (routeName: keyof typeof namedRoutes, args: StringMap = {}) => {
  return generatePath(namedRoutes[routeName].path, args);
};
