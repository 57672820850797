import { useContext } from "react";
import { Modal } from "react-bootstrap";
import { useDatabase } from "reactfire";
import { ref as dbRef } from "firebase/database";
import { clearKeepAliveListeners } from "../../hooks/firebase";
import { actions, Context } from "../../store";

import { Business, BusinessUser } from "../../types";
import { useDatabaseObjectData } from "../../hooks/firebase";

import styles from "./BusinessSwitcher.module.scss";
import { ImageLoader } from "../../shared/FormFields/ImageLoader";
import { activeAdminCheck } from "../../shared/Utils";

type BusinessPanelProps = {
  business: Partial<Business>;
  bid: string;
  uid: string;
};
function BusinessPanel({ business, bid, uid }: BusinessPanelProps) {
  const database = useDatabase();
  const [state] = useContext(Context);
  const userRef = dbRef(database, `/businessData/${bid}/users/${uid}/`);
  const user = useDatabaseObjectData<BusinessUser>(userRef, { errorOnEmpty: false });

  if (!business) {
    return null;
  }

  return (
    <div className={styles.businessInner}>
      <ImageLoader
        className={styles.businessImage}
        src={business.logo_thumbnail || business.logo}
        alt="business logo"
        blurhash={business.blurhash}
        defaultImage={<i className={`${styles.businessImage} fas fa-building fa-lg`}></i>}
      />
      <div className={styles.businessInfo}>
        {activeAdminCheck(state, false, bid) && (
          <i className={`${styles.visitedBusinessIcon} fas fa-business-time fa-lg`}></i>
        )}
        <div className={styles.businessName}>{business?.name}</div>
        <div>
          {business.owner === uid && <div>Owner</div>}
          {business.owner !== uid && user.status === "success" && <div>{user.data?.roleName || user.data?.role}</div>}
          {business.meta?.merchantId && <div>Merchant #{business.meta.merchantId}</div>}
        </div>
      </div>
    </div>
  );
}

export default function BusinessSwitcher() {
  const [state, dispatch] = useContext(Context);

  function setBusiness(businessId?: string) {
    console.log("Setting business to ", businessId ?? "reporting only mode");
    clearKeepAliveListeners();
    delete (window as any).sidebarScrollTop;
    dispatch({
      type: actions.SET_REQUESTED_BUSINESS,
      bid: businessId,
      isReportingOnly: !businessId,
    });
  }

  if (!state.uid) {
    return null;
  }

  const b = state.token?.claims?.adminAccess?.b;

  let validBusinesses = state.businesses;
  if (b!! && !state.businessIds.includes(b) && activeAdminCheck(state)) {
    validBusinesses = { ...state.businesses, [b]: state.visitedBusiness };
  }

  return (
    <Modal
      show={state.showBusinessPicker}
      backdrop={state.currentBid ? true : "static"}
      onHide={() => dispatch({ type: actions.HIDE_BUSINESS_PICKER })}
      centered={true}
      scrollable={true}
      size="sm"
    >
      <Modal.Header>
        <Modal.Title>Choose Business</Modal.Title>
      </Modal.Header>
      <ul className={`list-group list-group-flush ${styles.switcher}`}>
        {process.env.REACT_APP_REPORTING_ONLY_ACCESS && Object.keys(state.businesses).length > 1 && (
          <button
            onClick={() => setBusiness()}
            className={`list-group-item list-group-item-action ${state.isReportingOnly && "active"}`}
          >
            <div className={styles.businessInner}>
              <div className={styles.businessImage}>
                <i className="fas fa-city fa-lg"></i>
              </div>
              <div>
                <div className={styles.businessName}>All Businesses</div>
                <div>Reporting Only</div>
              </div>
            </div>
          </button>
        )}
        {Object.entries(validBusinesses).map(([bid, business]) => {
          return (
            <button
              key={bid}
              onClick={() => setBusiness(bid)}
              className={`list-group-item list-group-item-action ${bid === state.currentBid ? "active" : ""}`}
            >
              <BusinessPanel business={business} bid={bid} uid={state.uid!} />
            </button>
          );
        })}
      </ul>
    </Modal>
  );
}
